<template>
    <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5">
        <MedicationDispensedTable
            :tableData="medicationDispensed"
            :episode="episode"
            :headers="headers"
            :source="source"
            :users="users"
            :items-per-page="itemsPerPage"
            :isEditable="$can('edit', 'medication_dispensed')"
            :isDeletable="$can('delete', 'medication_dispensed')"
            @medication-dispensed-mutated="refreshMedicationDispensed"
        />
    </div>
</template>
<script>
import MedicationDispensedTable from './partials/MedicationDispensedTable.vue'
import camelcaseKeys from 'camelcase-keys'
import GET_MEDICATION_DISPENSED_TABLE from '@/graphql/queries/getMedicationDispensedForTable.gql'
import GET_USERS from '@/graphql/queries/getUsersForAdmin.gql'
import { format, parseISO } from 'date-fns'
import { USER_STATUS_TO_LIST } from '@/utils/constants'

export default {
    name: 'MedicationDispensed',
    components: { MedicationDispensedTable },
    props: {
        episode: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        medicationDispensed: {
            query: GET_MEDICATION_DISPENSED_TABLE,
            variables() {
                return {
                    episodeId: Number(this.episode.episodeId)
                }
            },
            skip() {
                if (!this.episode || !this.episode.episodeId) {
                    this.medicationDispensed = []
                    return true
                }
                return false
            },
            result({ data: { medicationDispensed } }) {
                let convertedMedicationDispensed = camelcaseKeys(medicationDispensed, {
                    deep: true
                })
                convertedMedicationDispensed = convertedMedicationDispensed.map((item) => {
                    return {
                        ...item,
                        number: item.number.toString(),
                        dispensedDate: format(parseISO(item.dispensedDate), 'yyyy-MM-dd')
                    }
                })
                this.medicationDispensed = [...convertedMedicationDispensed]
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            debounce: 200,
            query: GET_USERS,
            variables() {
                return {
                    filter: {}
                }
            },
            result({ data: { users, total } }) {
                this.total = total.aggregate.count
                users = camelcaseKeys(users || [], { deep: true })
                const activeUser = users?.filter((item) => {
                    if (
                        USER_STATUS_TO_LIST.includes(item.status) &&
                        item.role !== 'student' &&
                        item.role !== 'guest'
                    ) {
                        return { text: item.name, value: item.userId }
                    }
                })
                this.users = activeUser?.map((item) => {
                    return { text: item.name, value: item.userId }
                })
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            medicationDispensed: [],
            source: this.$route.name,
            users: [],
            headers: [
                {
                    text: 'Date',
                    align: 'left',
                    value: 'dispensedDate',
                    class: 'date--header w-120'
                },
                { text: 'Time', align: 'left', value: 'time', class: 'w-130 time--header' },
                {
                    text: 'Medication ',
                    align: 'left',
                    value: 'medication',
                    class: 'w-200 medication--header'
                },
                {
                    text: 'Added By',
                    align: 'left',
                    value: 'createdBy',
                    class: 'w-120 createdBy--header'
                },
                {
                    text: 'Date Added',
                    align: 'left',
                    value: 'created',
                    class: 'w-100 created--header'
                },
                { text: 'No.', align: 'left', value: 'number', class: 'w-100 number--header' },
                { text: 'Dose', align: 'left', value: 'strength', class: 'w-200 strength--header' },
                {
                    text: 'Dispensed by',
                    align: 'left',
                    value: 'dispensedBy',
                    class: 'w-200 dispensedBy--header'
                },
                { text: '', align: 'left', value: 'editRecord', fixed: true }
            ],
            itemsPerPage: 10
        }
    },
    methods: {
        refreshMedicationDispensed() {
            this.$apollo.queries.medicationDispensed.refetch()
        }
    }
}
</script>
